import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as Font from "expo-font";
import * as Linking from "expo-linking";
import React, { useEffect } from "react";
import { Platform, StyleSheet } from "react-native";
import SplashScreen from "react-native-splash-screen";
import Agenda from "./src/Pages/Agenda";
import AgendaProgram from "./src/Pages/AgendaProgram";
import Chat2 from "./src/Pages/Chat2";
import ChooseExpert from "./src/Pages/ChooseExpert";
import Condition from "./src/Pages/Condition";
import Consultation from "./src/Pages/Consultation";
import ConsultationForm from "./src/Pages/ConsultationForm";
import DetailAgenda from "./src/Pages/DetailAgenda";
import DetailChat2 from "./src/Pages/DetailChat2";
import DetailDiscussions from "./src/Pages/DetailDiscussions";
import DetailExpert from "./src/Pages/DetailExpert";
import DetailOrder from "./src/Pages/DetailOrder";
import DiscussPrograms from "./src/Pages/DiscussPrograms";
import EbookList from "./src/Pages/EbookList";
import Home from "./src/Pages/Home";
import Journal from "./src/Pages/Journal";
import Kota from "./src/Pages/Kota";
import LandingPage from "./src/Pages/LandingPage";
import LockedScreening from "./src/Pages/LockedScreening";
import Login from "./src/Pages/Login";
import Materials from "./src/Pages/Materials";
import NewDiscuss from "./src/Pages/NewDiscuss";
import NewHomePage from "./src/Pages/NewHomePage";
import Order from "./src/Pages/Order";
import Packages from "./src/Pages/Packages";
import PlaceOrder from "./src/Pages/PlaceOrder";
import ProductDetail from "./src/Pages/ProductDetail";
import Profile from "./src/Pages/Profile";
import ProfileForm from "./src/Pages/ProfileForm";
import Program from "./src/Pages/Program";
import ProgramDetail from "./src/Pages/ProgramDetail";
import PsikologiTestPage from "./src/Pages/PsikologiTestPage";
import ReadEbook from "./src/Pages/ReadEbook";
import Screening from "./src/Pages/Screening";
import ScreeningReport from "./src/Pages/ScreeningReport";
import ScreeningReportDetail from "./src/Pages/ScreeningReportDetail";
import ScreeningResult from "./src/Pages/ScreeningResult";
import SpeakerFeedback from "./src/Pages/SpeakerFeedback";
import Survey from "./src/Pages/Survey";
import SurveyDetailPage from "./src/Pages/SurveyDetail";
import SurveyResultPage from "./src/Pages/SurveyResult";
import Terms from "./src/Pages/Terms";
import Verification from "./src/Pages/Verification";
import WriteJournal from "./src/Pages/WriteJournal";
import { getPermission } from "./src/services/firebase";
import "./src/translation";
import ShortenerPage from "./src/Pages/ShortenerPage";
import MultipleChoosePage from "./src/Pages/MultipleChoose";

const Stack = createNativeStackNavigator();

Font.loadAsync({
  "Archivo-Regular": require("./src/assets/fonts/Archivo-Regular.ttf"),
  "Archivo-Bold": require("./src/assets/fonts/Archivo-Bold.ttf"),
  "Archivo-SemiBold": require("./src/assets/fonts/Archivo-SemiBold.ttf"),
  "Archivo-Medium": require("./src/assets/fonts/Archivo-Medium.ttf"),
  "Archivo-SemiBoldItalic": require("./src/assets/fonts/Archivo-SemiBoldItalic.ttf"),
});

export default function App() {
  useEffect(() => {
    if (Platform.OS !== "web") {
      SplashScreen.hide();
      getPermission();
    }

    async function getLang() {
      return await AsyncStorage.getItem("lang");
    }

    getLang().then((res) => {
      if (!res) {
        AsyncStorage.setItem("lang", "id");
      }
    });
  }, []);
  const prefix = Linking.createURL("/");
  const config = {
    screens: {
      Terms: "terms/id",
      Condition: "condition/id",
      TermsEnglish: "terms/en",
      ConditionEnglish: "condition/en",
      Survey: "quicksurvey",
      Shortener: "s/:id",
      // Nona: "nona/:id"
    },
  };
  const linking = {
    prefixes: [prefix, "https://app.fammi.ly"],
    config,
  };

  const params = Platform.OS === "web" ? window.location.search : "";

  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName={"Home"}>
        <Stack.Screen
          name="NewHomePage"
          component={NewHomePage}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="PsikologiTestPage"
          component={PsikologiTestPage}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="AgendaProgram"
          component={AgendaProgram}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="LockedScreening"
          component={LockedScreening}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ScreeningReportDetail"
          component={ScreeningReportDetail}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Screening"
          component={Screening}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="AllowedScreening"
          component={Screening}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ScreeningResult"
          component={ScreeningResult}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ScreeningReport"
          component={ScreeningReport}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SurveyDetail"
          component={SurveyDetailPage}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="MultipleChoose"
          component={MultipleChoosePage}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SurveyResult"
          component={SurveyResultPage}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ProgramDetail"
          component={ProgramDetail}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Program"
          component={Program}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ProductDetail"
          component={ProductDetail}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Journal"
          component={Journal}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="SpeakerFeedback"
          component={SpeakerFeedback}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ChooseExpert"
          component={ChooseExpert}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DetailExpert"
          component={DetailExpert}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="PlaceOrder"
          component={PlaceOrder}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ConsultationForm"
          component={ConsultationForm}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DetailOrder"
          component={DetailOrder}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Profile"
          component={Profile}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Chat"
          component={Chat2}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Login"
          component={Login}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Verification"
          component={Verification}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ProfileForm"
          component={ProfileForm}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DetailChat"
          component={DetailChat2}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="WriteJournal"
          component={WriteJournal}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Order"
          component={Order}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Terms"
          component={Terms}
          options={{
            headerShown: false,
          }}
          initialParams={{
            langParam: "id",
          }}
        />
        <Stack.Screen
          name="TermsEnglish"
          component={Terms}
          options={{
            headerShown: false,
          }}
          initialParams={{
            langParam: "eng",
          }}
        />
        <Stack.Screen
          name="EbookList"
          component={EbookList}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ReadEbook"
          component={ReadEbook}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Condition"
          component={Condition}
          options={{
            headerShown: false,
          }}
          initialParams={{
            langParam: "id",
          }}
        />
        <Stack.Screen
          name="ConditionEnglish"
          component={Condition}
          options={{
            headerShown: false,
          }}
          initialParams={{
            langParam: "eng",
          }}
        />
        <Stack.Screen
          name="LandingPage"
          component={LandingPage}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Consultation"
          component={Consultation}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Kota"
          component={Kota}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Agenda"
          component={Agenda}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Packages"
          component={Packages}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="NewDiscuss"
          component={NewDiscuss}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DiscussPrograms"
          component={DiscussPrograms}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DetailDiscussions"
          component={DetailDiscussions}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Survey"
          component={Survey}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="QuickSurvey"
          component={Survey}
          options={{
            headerShown: false,
          }}
          initialParams={{
            backButton: false,
            isQuick: true,
            isScreening: "1",
            queryString: params
          }}
        />
        <Stack.Screen
          name="quicksurvey"
          component={Survey}
          options={{
            headerShown: false,
          }}
          initialParams={{
            backButton: false,
            isQuick: true,
            isScreening: "1",
            queryString: params
          }}
        />
        <Stack.Screen
          name="Shortener"
          component={ShortenerPage}
          options={{
            headerShown: false,
          }}
          initialParams={{
            queryString: params
          }}
        />
        <Stack.Screen
          name="Nona"
          component={ProgramDetail}
          options={{
            headerShown: false,
          }}
          initialParams={{
            queryString: params
          }}
        />
        <Stack.Screen
          name="Materials"
          component={Materials}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DetailAgenda"
          component={DetailAgenda}
          options={{
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});
