import axios from "axios";
import { API_URL } from "@env";

export const setProgram = (code, type, memberId) => {
  return axios.get(
    API_URL + `/packages/check-program-code/${code}/${type}/${memberId}`
  );
};

export const setProgramAnon = (code, name, memberId) => {
  return axios.get(
    API_URL + `/packages/check-program-code-anon/${code}/${name}/${memberId}`
  );
};

export const getProgram = (type, memberId) => {
  return axios.get(API_URL + `/packages/get-program/${type}/${memberId}`);
};

export const getRecommendation = (memberId) => {
  return axios.get(API_URL + `/packages/get-recommendation/${memberId}`);
};

export const createDiscussion = (payload) => {
  return axios.post(API_URL + "/questions-kota", payload);
};

export const getListDiscussions = (additionalContentId, memberId) => {
  return axios.get(API_URL + `/questions-kota/${additionalContentId}/${memberId}`);
};

export const getListDiscussionsById = (additionalContentId, memberId, questionId) => {
  return axios.get(API_URL + `/questions-kota-by-id/${additionalContentId}/${memberId}/${questionId}`);
};

export const getComments = (additionalContentId, id, memberId) => {
  return axios.get(API_URL + `/questions-kota/${additionalContentId}/${id}/${memberId}`);
};

export const setFeedback = (id, memberId) => {
  return axios.get(API_URL + `/questions-kota-feedback/${id}/${memberId}`); 
}

export const setMark = (id, memberId) => {
  return axios.get(API_URL + `/questions-kota-mark/${id}/${memberId}`); 
}
export const subscribeTopic = (token, additional_content_id ) =>{
	return axios.get(API_URL + `/subscribe-topic/${token}/${additional_content_id}`); 
}
export const sendNotif = (additional_content_id, id ) =>{
	return axios.get(API_URL + `/send-message/${additional_content_id}/${id}`); 
}
export const sendNotifThread = (additional_content_id, id ) =>{
	return axios.get(API_URL + `/send-message/${additional_content_id}`); 
}

export const resubscribe = (memberId) => {
  return axios.get(API_URL + `/resubscribe/${memberId}`); 
}

export const getProgramId = (additional_content_id) => {
  return axios.get(API_URL + `/get-program/${additional_content_id}`);
}