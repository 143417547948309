import React, { Fragment, useEffect, useState } from "react";
import { Pressable, ScrollView, Text, View } from "react-native";
import Modal from "react-native-modal";
import {
  AddCircleIcon,
  ArrowLeft,
  ChevronDown,
  SearchBoldIcon,
  TickCircleIcon,
} from "../assets/icons";
import EmptyState from "./EmptyState";
import { getOptions } from "../services/survey";

function ModalMenuMultipleDropdownManual(props) {
  const { onChange, value, options, placeholder } = props;
  const [visible, setVisible] = useState(false);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [tempValue, setTempValue] = useState([]);
  const [tempOptions, setTempOptions] = useState({});
  const [categories, setCategories] = useState([]);
  const [chosenCategory, setChosenCategory] = useState(0);

  useEffect(() => {
    if (!visible) {
      return;
    }
    if (!Array.isArray(options) && options.indexOf(".json") > -1) {
      setLoadingOptions(true);
      setTimeout(() => {
        getOptions(options.replace(".json", ""))
        .then((res) => {
          setTimeout(() => {
            if (!res?.data.options) {
              return;
            }

            const parsedOptions = JSON.parse(res?.data.options.join(" "));
            if (Object.keys(parsedOptions).length > 0) {
              const categoryName = [];
              Object.keys(parsedOptions).map((i, index) => {
                categoryName.push(Object.keys(parsedOptions[i])[0]);
              });
              setCategories(categoryName);
            }

            setTempOptions(parsedOptions);

            setLoadingOptions(false);
          }, 1000);
        });
      }, 500);
    }
  }, [options, visible]);

  return (
    <Fragment>
      <Pressable
        style={{
          borderRadius: 10,
          borderWidth: 1,
          borderColor: "#eaeaea",
          borderStyle: "solid",
          backgroundColor: "#FFF",
          flexDirection: "row",
          alignItems: "center",
          padding: 12,
          justifyContent: "space-between",
        }}
        onPress={() => {
          setVisible(true);
        }}
      >
        <Text
          style={{
            color: value !== "" ? "#000" : "#999",
            fontFamily: "Archivo-Regular",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
          }}
        >
          {
            value &&
            value !== "Lainnya" &&
            value.split(" - ").length > 2 ?
            value?.split(" - ")[2] :
            (((value && value.split(" - ").length < 3) || value === "Lainnya"  ) ? "Lainnya" : 
              (placeholder ? placeholder : "Pilih")
            )
          }
        </Text>
        <ChevronDown />
      </Pressable>
      <Modal
        style={{
          padding: 0,
          width: "100%", 
          height: "100%", 
          margin: 0,
          maxWidth: "400px",
          marginLeft: "auto",
          marginRight: "auto"
        }}
        isVisible={visible}
        onBackdropPress={() => {
          setVisible(false);
        }}
      >
        <View
          style={{
            width: "100%",
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
            height: "100%"
          }}
        >
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 16,
              paddingLeft: 12,
              paddingRight: 12,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 12
              }}>
              <Pressable
                onPress={() => {
                  setVisible(false);
                }}
              >
                  <ArrowLeft/>
                </Pressable>
              <Text
                style={{
                  color: "#000",
                  fontFamily: "Archivo-Bold",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: 14,
                }}
              >
                Pilih Jawaban
              </Text>
            </View>
            <Pressable
              onPress={() => {
                onChange({
                  value: "Lainnya",
                  tempOptions: tempOptions
                });
                setVisible(false);
              }}
              style={{
                gap: 5,
                borderWidth: 1,
                paddingVertical: 9,
                paddingHorizontal: 10,
                borderColor: "#EAEAEA",
                flexDirection: "row",
                borderRadius: 10,
                alignItems: "center"
              }}
            >
              <AddCircleIcon/>
              <Text style={{fontFamily: "Archivo-Regular", fontSize: 12, color: "#6724E2"}}>Input Perilaku</Text>
            </Pressable>
          </View>
          {
            loadingOptions &&
            <EmptyState
                  Icon={SearchBoldIcon}
                  title="Mohon Menunggu"
                  desc="Sedang memuat pilihan jawaban"
                />
          }
          {
            !loadingOptions &&
            <View style={{
              padding: 16,
              backgroundColor: "#FFF",
              display: "flex",
              flexDirection: "row",
              gap: 12,
              justifyContent: 'flex-start',
              alignItems: "center",
            }}>
              {
                categories.map((i, index) => {
                  return <View style={{
                    paddingVertical: 10, 
                    paddingHorizontal: 12,
                    borderRadius: 10,
                    backgroundColor: chosenCategory === index ? "#6724E2" : "#EAEAEA",
                  }} 
                    onClick={() => {
                      console.log(index);
                      setChosenCategory(index);
                    }}
                  >
                    <Text style={{
                      fontFamily: "Archivo-Medium",
                      fontSize: 12,
                      textTransform: "capitalize",
                      color:  chosenCategory === index ? "#FFF" : "#000",
                    }}>{i}</Text>
                  </View>;
                })
              }
            </View>
          }
          {
            !loadingOptions &&
            <ScrollView style={{height: "100%", padding: 16}}>
              {tempOptions?.length > 0 ? (
                Object.keys(tempOptions[chosenCategory][categories[chosenCategory]])?.map((item, i) => {
                  return tempOptions[chosenCategory][categories[chosenCategory]][item].map((subitem, j) => {
                    let code = [];
                    if (subitem.indexOf(") ") > -1) {
                      code = subitem.split(") "); 
                    }
                    const p = `${categories[chosenCategory]} - ${item} - ${subitem}`;
                    
                    return (
                        <Pressable
                            onPress={() => {
                              setTempValue([`${p}`]);
                            }}
                          >
                            <View
                              style={{
                                borderRadius: 10,
                                padding: 12,
                                borderWidth: 1,
                                borderColor: tempValue.indexOf(p) > -1 ? "#6724E2" : "#EAEAEA",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: 10,
                                alignItems: "center",
                                marginBottom: 12
                              }}
                            >
                              {
                                tempValue.indexOf(p) > -1 ? <TickCircleIcon fill={true}/> : <TickCircleIcon/>
                              }
                              {
                                code.length > 0 &&
                                <Text
                                  style={{
                                    backgroundColor: tempValue.indexOf(p) > -1 ? "#6724E2" : "#000",
                                    fontFamily: "Archivo-Regular",
                                    fontSize: 12,
                                    fontStyle: "normal",
                                    fontWeight: 400,
                                    lineHeight: 14,
                                    color: "#FFF",
                                    paddingVertical: 4,
                                    paddingHorizontal: 10,
                                    borderRadius: 10
                                  }}
                                >
                                  {`${code[0].replace("(", "")}`}
                                </Text> 
                              }
                              <Text
                                style={{
                                  color: tempValue.indexOf(p) > -1 ? "#6724E2" : "#000",
                                  fontFamily: "Archivo-Regular",
                                  fontSize: 12,
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                  lineHeight: 14,
                                }}
                              >
                                { code.length > 1 ? code[1] : subitem}
                              </Text>
                            </View>
                          </Pressable>
                    );
                  });
                })
              ) : (
                <EmptyState
                  Icon={SearchBoldIcon}
                  title="Jawaban Tidak Ditemukan"
                  desc="Gunakan kata kunci yang lain untuk mencari jawaban"
                />
              )}
              </ScrollView>
          }
          <View
            style={{
              padding: 16,
              borderTopWidth: 1,
              borderTopColor: "#EAEAEA",
              justifyContent: "center",
              flexDirection: "row",
              gap: 16
            }}
          >
            <Pressable 
              style={{width: "50%",}}
              onPress={() => {
                if (tempValue.length < 1) {
                  onChange("");
                }
                setVisible(false);
              }}
            >
              <View style={{
                padding: 12,
                borderWidth: 1,
                borderColor: "#999",
                alignItems: "center",
                background: "#FFF",
                color: "#000",
                borderRadius: 10,
                width: "100%"
              }}>
                <Text style={{fontFamily: "Archivo-Bold", fontSize: 12}}>Kembali</Text>
              </View>
            </Pressable>
            <Pressable 
              style={{width: "50%"}}
              onPress={() => {
                if (tempValue.length < 1) {
                  return;
                }

                onChange(tempValue[0]);
                setVisible(false);
              }}
            >
              <View style={{
                padding: 12,
                alignItems: "center",
                background: tempValue.length > 0 ? "#6724E2" : "#999",
                borderRadius: 10,
                width: "100%"
              }}>
                <Text style={{color: "#FFF", fontFamily: "Archivo-Bold", fontSize: 12}}>Simpan Data</Text>
              </View>
            </Pressable>
          </View>
        </View>
      </Modal>
    </Fragment>
  );
}

export default ModalMenuMultipleDropdownManual;
