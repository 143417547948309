import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Image,
  Pressable,
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import {
  BookBlackIcon,
  Calendar2Icon,
  ChatCircle,
  ChatIcon,
  DentIcon,
  EditIcon,
  EmojiHappyIcon,
  HeartParent,
  HeartTickIcon,
  Message2Black,
  MessengerFillIcon,
} from "../assets/icons";
import {
  AgendaProgramScreeningCard,
  AppBar,
  FeatureButton,
  FormNewDiscuss,
  ListDiscussions,
  ListExpert,
  ModalComment,
  ModalInfoQuota,
  ModalQuotaOver,
  ProgramServiceFilter,
  Snackbar,
  ZoomCard,
} from "../components";
import ChooseExpert from "./ChooseExpert";
import { getMemberCredits } from "../services/member_credits";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { createDiscussion, getListDiscussions, sendNotif, subscribeTopic } from "../services/programs";
import { useTranslation } from "react-i18next";
import md5 from "md5";
import { getProgramAgendas } from "../services/agenda";
import moment from "moment";
import MaterialAgendaCard from "../components/MaterialAgendaCard";


const ProgramDetail = ({ route }) => {
  const [serviceFilter, setServiceFilter] = useState("all");
  const navigation = useNavigation();
  const [quotaOver, setQuotaOver] = useState(false);
  const [quotaInfo, setQuotaInfo] = useState(false);
  const { params } = route;
  const [member, setMember] = useState(undefined);
  const [memberMode, setMemberMode] = useState("");
  const [credit, setCredit] = useState([]);
  const [discussions, setDiscussions] = useState([]);
  const [dataConsultants, setDataConsultants] = useState([]);
  const [refreshing, setRefreshing] = React.useState(false);
  const [additionalContentId, setAdditionalContentId] = useState(undefined);
  const { t } = useTranslation();
  const [openComment, setOpenComment] = useState(false);
  const [question, setQuestion] = useState("");
  const [isOnSend, setIsOnSend] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isSnackbar, setIsSnackbar] = useState(false);
  const [msg, setMsg] = useState("");
  const [agendas, setAgendas] = useState([]);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    setTimeout(() => {
      setRefreshing(false);
    }, 2000);
  }, []);

  const icons = {
    9: HeartTickIcon, // figure
    10: EmojiHappyIcon, // partner
    11: MessengerFillIcon, // expert
  };

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMember(memberId);

      const mode = await AsyncStorage.getItem("792f3e7");
      setMemberMode(mode);

      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    if (!member) {
      return;
    }

    getMemberCredits(member).then((res) => {
      const resp = res?.data;
      const creditData = [];
      resp.map((c) => {
        if (c.status !== `program-${params.program?.additionalContentId}`) {
          return;
        }

        creditData.push({
          type: c.type,
          name: c.name,
          total: c.total,
        });
      });
      setCredit(creditData);
    });
    getListDiscussions(params.program?.additionalContentId, member).then(
      (res) => {
        setDiscussions(res?.data);
      }
    );

    getProgramAgendas(params.program?.additionalContentId)
    .then((res) => {
      const filteredAgenda = res?.data?.filter((a) => {
        return moment(a.scheduledDate) > moment();
      });
      if (filteredAgenda.length > 0) {
        setAgendas([filteredAgenda[0]]);
      }
    })

    setAdditionalContentId(params?.program?.additionalContentId);
  }, [
    member,
    params?.program?.additionalContentId,
    params?.refresh,
    refreshing,
  ]);
  const LISTMENUS = [
    {
      id: 1,
      title: "Survey",
      icon: <EditIcon />,
      action: () => {
        navigation.navigate("Survey", {
          additionalContentId: params?.program?.additionalContentId,
          isScreening: "0"
        });
      },
    },
    {
      id: 2,
      title: "Screening",
      icon: <EditIcon />,
      action: () => {
        navigation.navigate("Survey", {
          additionalContentId: params?.program?.additionalContentId,
          isScreening: "1"
        });
      },
    },
    {
      id: 3,
      title: "Materi",
      icon: <BookBlackIcon />,
      action: () => {
        navigation.navigate("EbookList", {
          additionalContentId: additionalContentId,
        });
      },
    },
    {
      id: 4,
      title: "Agenda",
      icon: <Calendar2Icon width={18} height={18} />,
      action: () => {
        navigation.navigate("AgendaProgram", {
          additionalContentId: params?.program?.additionalContentId,
          memberId: member,
          program: params.program
        });
      },
    },

    {
      id: 5,
      title: "Ruang Diskusi",
      icon: <Message2Black />,
      action: () => {
        navigation.navigate("DiscussPrograms", {
          additionalContentId: params?.program?.additionalContentId,
        });
      },
    },
    {
      id: 6,
      title: "Info Kuota",
      icon: <DentIcon />,
      action: () => {
        setQuotaInfo(true);
      },
    },
  ];
  const onSubscribeTopic = () => {
    subscribeTopic(member, additionalContentId);
    sendNotif(additionalContentId, selectedComment.questionId+"comment");
  };

  const onSendComment = async () => {
    setIsOnSend(true);
    if (question.length === 0) {
      setMsg("Harap isi komentar kamu");
      setIsSnackbar(true);
      setIsOnSend(false);
      return;
    }
    const payload = {
      title: "",
      categories: selectedComment?.categories,
      tags: selectedComment?.tags,
      question: question,
      memberId: member,
      questionId: md5(new Date().getTime()),
      additionalContentId: additionalContentId,
      parentId: selectedComment.questionId,
    };
    await createDiscussion(payload)
      .then((res) => {
        if (res?.data || res?.data === "true" || res?.data === true) {
          setMsg("Komentar kamu berhasil ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
          onSubscribeTopic();
        } else {
          setMsg("Komentar kamu gagal ditambahkan");
          setOpenComment(false);
          setIsSnackbar(true);
          setIsOnSend(false);
          setQuestion("");
        }
      })
      .catch((err) => {
        setMsg("Komentar kamu gagal ditambahkan");
        setOpenComment(false);
        setIsSnackbar(true);
        setIsOnSend(false);
        setQuestion("");
      });
  };
  const onOpenComment = (data) => {
    setSelectedComment(data);
    setOpenComment(true);
  };
  return (
    <React.Fragment>
      {
        memberMode !== "anon" &&
        <AppBar title="Program" isBack />
      }
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <View style={styles.container}>
          <View style={styles.headerBox}>
            <Image
              source={{
                uri: `https://fammi.ly/uploads/products/images/${params.program?.productImage}`,
              }}
              style={styles.image}
            />
            <Text style={styles.title}>{params.program.productName}</Text>
            {params.program.productDescription && (
              <Text style={styles.desc}>
                {params.program.productDescription}
              </Text>
            )}
          </View>
          <View style={{ paddingHorizontal: 16, paddingBottom: 16 }}>
            <View
              style={{ ...styles.buttonContainer, flexDirection: "column" }}
            >
              <View style={styles.flexRow}>
                <ChatIcon isActive />
                <Text style={{...styles.textLabel, fontWeight: 700}}>
                  {/* {t("program-detail:add-discussion")} */}
                  Forum Grup Diskusi
                </Text>
              </View>
              <Pressable
                onPress={() => {
                  navigation.navigate("NewDiscuss", {
                    ...params,
                    additionalContentId: params?.program?.additionalContentId,
                  });
                }}
                style={styles.input}
              >
                <Text style={styles.textInput}>
                  {t("program-detail:placeholder-add-new-discussion")}
                </Text>
              </Pressable>
            </View>
            {
              memberMode !== "anon" &&
              <View style={{ marginTop: 16 }}>
                <Text
                  style={{
                    ...styles.txKuota,
                    marginLeft: 0,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {t("program-detail:program-menu")}
                </Text>
                <View
                  style={{
                    ...styles.flexRow,
                    width: "100%",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                    marginTop: 12,
                  }}
                >
                  {LISTMENUS.map((item, index) => (
                    <Pressable
                      onPress={item.action}
                      style={styles.menus}
                      key={index}
                    >
                      <View style={styles.parentIcon}>{item.icon}</View>
                      <Text style={styles.textMenus}>{item.title}</Text>
                    </Pressable>
                  ))}
                </View>
              </View>
            }
          </View>
        </View>
        {
          agendas?.length > 0 &&
          <View
            style={{
              backgroundColor: "#EEEAFF",
              paddingBottom: 16,
            }}
          >
              <View
                style={{
                  paddingHorizontal: 16,
                  paddingBottom: ["survey", "screening"].indexOf(agendas[0].agendaType) > -1 ? 0 : 12,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#EEEAFF",
                }}
              >
                <Text
                  style={{
                    ...styles.txKuota,
                    marginLeft: 0,
                    fontSize: 14,
                    color: "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  Agenda Terdekat
                </Text>
                <Pressable
                  onPress={() => {
                    navigation.navigate("AgendaProgram", {
                      additionalContentId: params?.program?.additionalContentId,
                      memberId: member,
                      program: params?.program
                    });
                  }}
                >
                  <Text
                    style={{
                      ...styles.txKuota,
                      marginLeft: 0,
                      fontSize: 12,
                      color: "#6724E2",
                    }}
                  >
                    Lihat Semua
                  </Text>
                </Pressable>
              </View>
              <View style={{marginLeft: 4}}>
                {
                  agendas?.map((a, index) => {
                    switch(a.agendaType) {
                      case "youtube": return <ZoomCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                      case "diskusi": return <ZoomCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                      case "zoom": return <ZoomCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                      case "materi": return <MaterialAgendaCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                      case "survey": return <AgendaProgramScreeningCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                      case "screening": return <AgendaProgramScreeningCard data={a} hideDate={true} additionalContentId={additionalContentId} memberId={member}/>;
                    }
                  })
                }
              </View>
          </View>
        }
        <View
          style={{
            backgroundColor: "#EEEAFF",
          }}
        >
          {discussions?.length > 0 && (
            <View
              style={{
                paddingHorizontal: 16,
                paddingBottom: 12,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#EEEAFF",
              }}
            >
              <Text
                style={{
                  ...styles.txKuota,
                  marginLeft: 0,
                  fontSize: 14,
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                {t("program-detail:discuss-program")}
              </Text>
              <Pressable
                onPress={() => {
                  navigation.navigate("DiscussPrograms", {
                    additionalContentId: params?.program?.additionalContentId,
                  });
                }}
              >
                <Text
                  style={{
                    ...styles.txKuota,
                    marginLeft: 0,
                    fontSize: 12,
                    color: "#6724E2",
                  }}
                >
                  {t("program-detail:all")}
                </Text>
              </Pressable>
            </View>
          )}
          <View
            style={{
              paddingHorizontal: 16,
              backgroundColor: "#EEEAFF",
              paddingBottom: 16,
            }}
          >
            {discussions?.slice(0, 3).map((item, index) => (
              <ListDiscussions
                t={t}
                key={index}
                data={item}
                memberId={member}
				openComment={openComment}
                onPress={(alreadyLike) => {
                  navigation.navigate("DetailDiscussions", {
                    additionalContentId: params?.program?.additionalContentId,
                    id: item?.questionId,
                    data: item,
                    alreadyLike,
                  });
                }}
                onOpenComment={onOpenComment}
              />
            ))}
          </View>
          {dataConsultants.length > 0 && (
            <View
              style={{
                paddingHorizontal: 16,
                paddingBottom: 12,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#EEEAFF",
              }}
            >
              <Text
                style={{
                  ...styles.txKuota,
                  marginLeft: 0,
                  fontSize: 14,
                  color: "rgba(0, 0, 0, 0.87)",
                }}
              >
                {t("program-detail:title-consult")}
              </Text>
              <Pressable
                onPress={() => {
                  navigation.navigate("ChooseExpert", {
                    type: "all",
                    status: `program-${params.program?.additionalContentId}`,
                  });
                }}
              >
                <Text
                  style={{
                    ...styles.txKuota,
                    marginLeft: 0,
                    fontSize: 12,
                    color: "#6724E2",
                  }}
                >
                  {t("program-detail:all")}
                </Text>
              </Pressable>
            </View>
          )}
          <ChooseExpert
            setDataConsultants={setDataConsultants}
            route={{
              params: {
                type: "all",
                status: `program-${params.program?.additionalContentId}`,
                hideHeader: true,
                hideCategory: true,
                isProgram: true,
                quota: credit[0]?.total,
              },
            }}
          />
        </View>
      </ScrollView>
      <ModalQuotaOver visible={quotaOver} onClose={() => setQuotaOver(false)} />
      <ModalInfoQuota
        credit={credit}
        visible={quotaInfo}
        onClose={() => setQuotaInfo(false)}
      />
      <ModalComment
        setValue={setQuestion}
        isOnSend={isOnSend}
        value={question}
        visible={openComment}
        onSendComment={onSendComment}
        onClose={() => {
          setOpenComment(false);
          setSelectedComment(null);
		  setQuestion('')
        }}
      />
      <Snackbar
        visible={isSnackbar}
        hideDialog={() => {
          setIsSnackbar(false);
        }}
        title={msg}
      />
    </React.Fragment>
  );
};

const KuotaProgram = ({ Icon, kuota, label }) => {
  return (
    <View style={styles.kuota}>
      <View style={styles.flexRow}>
        {/* <Icon width={18} height={18} fill="#000" /> */}
        <Text style={styles.txKuota}>{kuota.replace(" sesi", "")}x</Text>
      </View>
      <Text
        style={[styles.desc, { fontSize: 12, lineHeight: 13, marginTop: 6 }]}
      >
        {label}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  textLabel: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 10,
    lineHeight: 11,
    marginLeft: 6,
  },
  container: {
    backgroundColor: "#EEEAFF",
  },
  headerBox: {
    backgroundColor: "#fff",
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  image: {
    width: "100%",
    height: 61,
    borderRadius: 10,
    resizeMode: "cover",
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 15,
    marginTop: 16,
    marginBottom: 10,
  },
  desc: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 13,
    lineHeight: 16,
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  kuota: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#eaeaea",
    padding: 10,
    flex: 1,
  },
  txKuota: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 14,
    lineHeight: 15,
    marginLeft: 6,
  },
  services: {
    paddingHorizontal: 16,
    paddingBottom: 30,
    flex: 1,
  },
  buttonContainer: {
    borderRadius: 10,
    padding: 10,
    paddingTop: 12,
    paddingBottom: 12,
    flexDirection: "row",
    backgroundColor: "#FFF",
    marginTop: 16,
    justifyContent: "space-between",
  },
  input: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    backgroundColor: "#F6F6F6",
    paddingTop: 14,
    paddingRight: 12,
    paddingBottom: 14,
    paddingLeft: 12,
    marginTop: 10,
  },
  textInput: {
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
    marginTop: 0,
    color: "#999"
  },
  menus: {
    width: "48.5%",
    borderRadius: 10,
    backgroundColor: "#fff",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginBottom: 10,
  },
  textMenus: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 12,
  },
  parentIcon: {
    width: 30,
    height: 30,
    backgroundColor: "#EAEAEA",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 30 / 2,
    marginRight: 8,
  },
});

export default ProgramDetail;
